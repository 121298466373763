body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* My CSS */

.main {
  min-height: 92vh;
  min-height: calc(100vh - (67px + 70px));
}

.navbar {
	z-index: 1050;
}

.sticky-offset {
  top: 82px;
}

.activity-form label {
	font-weight: 400;
}

.form-error {
  color: red;
}

.activity-card h3 a,
.activity-card h3 a:hover {
	color: var(--dark);
	text-decoration: none;
}

.activity-card-info {
  margin-bottom: 8px;
}

.activity-card-time-info {
	font-weight: 400;
	font-size: 14px;
}

.empty-activity-card {
  height: 200px;
}

.empty-activity-icon {
  color: #d1d1d1;
}

.iti {
  width: 100%;
}

.loading-glow {
  animation: glow 1.5s ease-in-out infinite;
  background: #eee;
  color: transparent;
  cursor: progress;
}

